import { string } from 'prop-types'

import { StyledTitle } from './styles'

const propTypes = {
  children: string,
}

export const Title = ({ children }) => {
  if (!children) return null

  return (
    <StyledTitle variant="bodyXlMedium" as="p" color="neutral.100">
      {children}
    </StyledTitle>
  )
}

Title.propTypes = propTypes
