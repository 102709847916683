import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const Wrapper = styled.div`
  max-width: 100%;

  ${media.up('4xl')} {
    max-width: 392px;
  }
`

export { Wrapper }
