import { object } from 'prop-types'

import ConditionalForm from 'components/ConditionalForm'
import HubspotForm from 'components/HubspotForm/HubspotForm'

import { Wrapper } from './styles'

const propTypes = {
  form: object,
}

const TYPES = {
  ContentfulComponentConditionalForm: ConditionalForm,
  ContentfulHubspotForm: HubspotForm,
}

const Form = ({ form, ...others }) => {
  if (!form) return null

  const FormElement = TYPES[form?.__typename]

  return (
    <Wrapper {...others}>
      <FormElement {...form} />
    </Wrapper>
  )
}

Form.propTypes = propTypes

export default Form
