import React, { useState } from 'react'
import { array, arrayOf, bool, func, shape, string } from 'prop-types'

import Radio from 'components/FormElements/components/Radio'

import SelectedAnswerContent from '../SelectedAnswerContent'
import { Title } from '../Title'

import { Wrapper } from './styles'

const propTypes = {
  mainQuestionAnswers: arrayOf(
    shape({
      content: array,
      id: string,
      title: string,
    }),
  ),
  mainQuestionLabel: string,
  shouldHide: bool,
  showInnerForm: func,
  title: string,
}

const defaultProps = {
  showInnerForm: () => {},
  shouldHide: false,
  mainQuestionAnswers: [],
}

const MainQuestion = ({
  mainQuestionAnswers,
  mainQuestionLabel,
  shouldHide,
  showInnerForm,
  title,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState({})

  const handleOnChange = e => {
    const answerId = e.target.value
    if (!answerId) return
    const answer = mainQuestionAnswers.find(
      findAnswer => findAnswer.id === answerId,
    )
    if (!answer) return
    setSelectedAnswer({ ...answer })
  }

  return (
    <Wrapper shouldHide={shouldHide} data-testid="main-question-wrapper">
      <Title>{title}</Title>
      {mainQuestionLabel && mainQuestionAnswers && (
        <form data-testid="form" onSubmit={() => {}}>
          <Radio
            onChange={handleOnChange}
            label={mainQuestionLabel}
            options={mainQuestionAnswers.map(option => ({
              value: option.id,
              label: option.title,
            }))}
          />
        </form>
      )}

      <SelectedAnswerContent
        showInnerForm={showInnerForm}
        {...selectedAnswer}
      />
    </Wrapper>
  )
}

MainQuestion.propTypes = propTypes
MainQuestion.defaultProps = defaultProps
export default MainQuestion
