import React, { useCallback, useState } from 'react'
import { array, string } from 'prop-types'

import InnerForm from './components/InnerForm'
import MainQuestion from './components/MainQuestion'

const propTypes = {
  mainQuestionAnswers: array,
  mainQuestionLabel: string,
  title: string,
}

const ConditionalForm = ({ mainQuestionAnswers, mainQuestionLabel, title }) => {
  const [innerForm, setInnerForm] = useState({})

  const handleShowInnerForm = useCallback(innerFormToOpen => {
    if (!innerFormToOpen) return
    setInnerForm(innerFormToOpen)
  }, [])

  const handleHideInnerForm = () => setInnerForm({})

  return (
    <>
      <MainQuestion
        title={title}
        mainQuestionLabel={mainQuestionLabel}
        mainQuestionAnswers={mainQuestionAnswers}
        shouldHide={!!innerForm.hubspotFormId}
        showInnerForm={handleShowInnerForm}
      />
      {innerForm.hubspotFormId && (
        <InnerForm hideInnerForm={handleHideInnerForm} innerForm={innerForm} />
      )}
    </>
  )
}

ConditionalForm.propTypes = propTypes

export default ConditionalForm
