import React from 'react'
import { arrayOf, func, object } from 'prop-types'

import SelectedAnswerText from '../SelectedAnswerText'

import { StyledButtonCta, Wrapper } from './styles'

const propTypes = {
  content: arrayOf(object),
  showInnerForm: func,
}

const defaultProps = {
  showInnerForm: () => {},
  content: [],
}

const SelectedAnswerContent = ({ content, showInnerForm }) => {
  return (
    <Wrapper>
      {content?.map(item =>
        item.__typename === 'ContentfulComponentConditionalFormAnswerText' ? (
          <SelectedAnswerText key={item?.id} {...item} />
        ) : (
          <StyledButtonCta
            key={item?.id}
            onClick={
              item?.actionType === 'showInnerForm'
                ? () => showInnerForm({ ...item?.innerForm })
                : undefined
            }
            as={item?.actionType === 'showInnerForm' ? 'button' : 'a'}
            href={item?.actionHref}
            width="fixed"
            {...item}
          >
            {item.label}
          </StyledButtonCta>
        ),
      )}
    </Wrapper>
  )
}

SelectedAnswerContent.propTypes = propTypes
SelectedAnswerContent.defaultProps = defaultProps
export default SelectedAnswerContent
