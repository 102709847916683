import { node } from 'prop-types'

import RichText from 'components/RichText'

const propTypes = {
  children: node,
}

const Title = ({ children, ...others }) => {
  return (
    <RichText
      variant={{
        _: 'headingMdLight',
        '5xl': 'headingXlLight',
      }}
      {...others}
    >
      {children}
    </RichText>
  )
}

Title.propTypes = propTypes

export default Title
