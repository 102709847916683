import { styled } from '@creditas-ui/system'

import HubspotForm from 'components/HubspotForm/HubspotForm'

const StyledHubspotForm = styled(HubspotForm)`
  .formTitle {
    text-align: center;
    font-weight: 600;
  }
`

export { StyledHubspotForm }
