import React from 'react'
import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  text: string,
}

const SelectedAnswerText = ({ text }) => {
  if (!text) return null

  return (
    <Typography data-testid="selected-answer-text" as="p" variant="bodySmLight">
      {text}
    </Typography>
  )
}

SelectedAnswerText.propTypes = propTypes
export default SelectedAnswerText
