import React from 'react'
import { func, object } from 'prop-types'

import { StyledHubspotForm } from './styles'

const propTypes = {
  hideInnerForm: func,
  innerForm: object,
}

const InnerForm = ({ hideInnerForm, innerForm }) => {
  return (
    <StyledHubspotForm
      showGoBackOnFirstStep
      handleGoBackOnFirstStep={hideInnerForm}
      formWithWebCardWrapper={false}
      {...innerForm}
    />
  )
}

InnerForm.propTypes = propTypes
export default InnerForm
