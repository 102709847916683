import { node } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: node,
}

const Description = ({ children, ...others }) => {
  return (
    <Typography
      color="neutral.80"
      variant={{
        _: 'bodyMdRegular',
        '5xl': 'bodyXlLight',
      }}
      {...others}
    >
      {children}
    </Typography>
  )
}

Description.propTypes = propTypes

export default Description
