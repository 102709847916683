import { node } from 'prop-types'

import { Container, Section } from './styles'

const propTypes = {
  children: node,
}

const Wrapper = ({ children, ...others }) => {
  return (
    <Section as="section" {...others}>
      <Container>{children}</Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes

export default Wrapper
