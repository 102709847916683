import { ButtonPrimaryWithArrow } from '@creditas-ui/button'
import { styled } from '@creditas-ui/system'

const Wrapper = styled.div``

const StyledButtonCta = styled(ButtonPrimaryWithArrow)`
  text-decoration: auto;

  &:not(:only-child):not(:first-of-type) {
    margin-top: 16px;
  }
`

export { Wrapper, StyledButtonCta }
