import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'
import { Grid as UiGrid } from 'components/Grid'

export const Section = styled(CreditasContainer)`
  padding-top: ${selectTheme('spacingTop.6xl')};
  padding-bottom: ${selectTheme('spacingBottom.6xl')};

  ${media.up('2xl')} {
    padding-top: ${selectTheme('spacingTop.12xl')};
    padding-bottom: ${selectTheme('spacingBottom.12xl')};
  }

  ${media.up('5xl')} {
    padding-top: ${selectTheme('spacingTop.20xl')};
    padding-bottom: ${selectTheme('spacingBottom.20xl')};
  }
`

export const Container = styled(UiGrid)`
  grid-template-areas:
    '${'info '.repeat(4)}'
    '${'form '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas: '${'info '.repeat(4)} ${'form '.repeat(4)}';
  }

  ${media.up('5xl')} {
    grid-template-areas: '. ${'info '.repeat(6)} . ${'form '.repeat(3)} .';
  }
`
