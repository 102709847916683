import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

export const InfoContainer = styled.div`
  grid-area: info;
`

export const FormContainer = styled.div`
  grid-area: form;
  position: relative;

  ${media.down('2xl')} {
    margin-top: ${selectTheme('spacingTop.8xl')};
  }
`

export const PreTitle = styled(RichText)`
  text-transform: uppercase;
`
