import { object, oneOf, string } from 'prop-types'

import { FormContainer, InfoContainer, PreTitle } from './base.styles'
import Description from './components/Description'
import Form from './components/Form'
import Title from './components/Title'
import Wrapper from './components/Wrapper'

const headingTypes = oneOf(['h1', 'h2'])

const propTypes = {
  description: string,
  descriptionRichText: string,
  form: object,
  id: string,
  preTitle: string,
  preTitleHeadingTag: headingTypes,
  title: string,
  titleHeadingTag: headingTypes,
  titleRichText: string,
}

const HeroForm = ({
  description,
  descriptionRichText,
  form,
  id,
  preTitle,
  preTitleHeadingTag,
  title,
  titleHeadingTag,
  titleRichText,
  ...others
}) => {
  const preTitleAs = preTitleHeadingTag || 'h1'
  const titleAs = preTitle ? titleHeadingTag || 'h2' : 'h1'

  return (
    <Wrapper id={id} {...others}>
      <InfoContainer>
        {preTitle && (
          <PreTitle
            as={preTitleAs}
            color="neutral.60"
            variant="exceptionMdBold"
          >
            {preTitle}
          </PreTitle>
        )}
        <Title as={titleAs} color="neutral.90">
          {titleRichText || title}
        </Title>
        <Description color="neutral.80">
          {descriptionRichText || description}
        </Description>
      </InfoContainer>
      <FormContainer>
        <Form form={form} />
      </FormContainer>
    </Wrapper>
  )
}

HeroForm.propTypes = propTypes

export default HeroForm
