import { styled } from '@creditas-ui/system'

import RichText from 'components/RichText'

const Typography = styled(RichText)`
  margin-bottom: 14px;
  display: block;

  &:not(:first-of-type):not(:only-child) {
    margin-top: 14px;
  }
`

export { Typography }
